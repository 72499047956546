function setRoutes(
  $rootScope,
  $route,
  RouterFactory,
  OAuthRoutesFactory,
  ApiUserFactory,
  ApiContentFactory,
  ApiAgreementFactory
) {

  function ContentResolver () {
    return ApiContentFactory.get(
      $route.current.params.contentKey
    );
  }

  function AgreementResolver () {
    return ApiAgreementFactory.get(
      $route.current.params.contentKey,
      $route.current.params.agreementKey
    );
  }
  
  // add this modules routes to the routern
  var routes = [
    {
      url: '/create',
      config: {
        templateUrl: 'create.html',
        controller: 'CreateAgreementCtrl',
        resolve: {
          User: ApiUserFactory.getUser
        }
      }
    },{
      url: '/receipt/:contentKey/:agreementKey',
      config: {
        templateUrl: 'receipt.html',
        controller: 'AgreementReceiptCtrl',
        resolve: {
          Agreement: AgreementResolver
        }
      }
    },{
      url: '/manage/:contentKey/:agreementKey',
      config: {
        templateUrl: 'manage.html',
        controller: 'ManageAgreementCtrl',
        resolve: {
          Content: ContentResolver,
          Agreement: AgreementResolver
        }
      }
    }
  ];
  
  routes.push(OAuthRoutesFactory.getRoutes());
  
  RouterFactory.addRoutes(routes);

  // Handle failed resolves
  $rootScope.$on('$routeChangeError', function (event, current, previous, rejection) {
    throw new Error(event.name + ': ' + JSON.stringify(rejection));
  });

  $rootScope.$on('$routeChangeSuccess', function() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  });

}
