function AgreementFactory (
  $q,
  $log,
  ApiContentFactory,
  ApiAgreementFactory,
  OAuthFactory,
  BankIDFactory,
  DICT
) {

  // externally visible:
  // ----------

  var service = {
    create: create,
    cancel: cancel,
    sign: sign,
    isOwner: isOwner,
    resolver: resolver
  };

  
  function _createAgreementContent (opt) {
    
    var content = {
      subject: opt.subject,
      type: 'other',
      parties: opt.parties,
      template: 'pdf_viewer',
      labels: {
        agreement: true
      },
      files: [{
        content_type: 'application/pdf',
        name: opt.fileName,
        data: opt.data
      }]
      
    };
    
    var dfd = $q.defer();
    
    function onSuccess(res) {
      dfd.resolve({
        contentKey: res.headers('kivra-objkey'),
        agreementKey: res.data.agreement_key
      });
    }
    
    ApiContentFactory
      .create(JSON.stringify(content))
      .then(
        onSuccess,
        dfd.reject
      );

    return dfd.promise;
  }

  function isOwner (agreement) {
    if (!agreement) {
      return false;
    }
    return agreement.owner === 'user_' + OAuthFactory.getUkey();
  }

  function cancel (opt) {

    var dfd = $q.defer();

    ApiAgreementFactory
      .cancel(opt.contentKey, opt.agreementKey)
      .then(function (res) {
        dfd.resolve(res);
      })
      .catch(function (err) {
        dfd.reject(err);
      });
    
    return dfd.promise;
  }
  
  function create (opt) {

    var dfd = $q.defer();
    
    _createAgreementContent(opt)
      .then(function (res) {
        dfd.resolve(res);
      })
      .catch(function (err) {
        dfd.reject(err);
      });
    
    return dfd.promise;
  }

  function resolver () {
    return ApiAgreementFactory.get();
  }

  function sign (opt) {

    var dfd = $q.defer();

    function catchError(err) {
      if (err.data && err.data.message_code) {
        dfd.reject(DICT['bankid_' + err.data.message_code.toLowerCase()]);
      } else {
        dfd.reject(err);
      }
    }
    
    ApiAgreementFactory
      .initSign(opt)
      .then(function (res) {
        return BankIDFactory.sign(res);
      })
      .then(function(res) {
        return ApiAgreementFactory.sign(
          angular.extend(opt, {
            orderKey: res,
          })
        );
      },catchError, function (update) {
        dfd.notify(update);
      })
      .then(function (res) {
        dfd.resolve(res);
      })
      .catch(catchError);
    
    return dfd.promise;
  }
  


  return service;
}
