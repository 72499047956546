/* 
 * Factory to wrap http interactions to the bank part of core's api.
 * It's methods returns promises resolving to result objects
 */

function ApiTenantFactory(
  $http,
  $q,
  $sessionStorage,
  CoreApiReqFactory
) {
  
  var sessionData = $sessionStorage.tenants;

  function clear () {
    sessionData = undefined;
  }

  function getUserTenants () {
    var dfd = $q.defer();
    var url = CoreApiReqFactory.getBaseUrl() + '/tenant';

    if (sessionData) {
      dfd.resolve(sessionData);
    } else {
      $http
        .get(url)
        .then(
          function (res) {
            sessionData = res.data;
            dfd.resolve(res.data);
          },
          function (err) {
            if (err.id) {
              dfd.resolve(err);
            } else {
              dfd.reject(err);
            }
          }
        );
    }


    return dfd.promise;
  }

  function getUserTenant (tenantKey) {
    var dfd = $q.defer();
    var url = CoreApiReqFactory.getBaseUrl() + '/tenant/' + tenantKey;

    if (sessionData) {
      sessionData.some(function (tenant) {
        if (tenant.key === tenantKey) {
          return dfd.resolve(tenant);
        }
      });
    } else {
      $http
        .get(url)
        .then(function (res) {
          dfd.resolve(res.data);
        })
        .catch(function (err) {
          if (err.id) {
            dfd.resolve(err);
          } else {
            dfd.reject(err);
          }
        });
    }

    return dfd.promise;
  }

  function setUserTenantState (tenantKey, state) {
    var dfd = $q.defer();
    var url = [
      CoreApiReqFactory.getBaseUrl(),
      'tenant',
      tenantKey,
      'status'
    ].join('/');
    var body = {
      state: state
    };

    $http
      .put(url, body)
      .then(function (res) {
        if (sessionData) {
          sessionData.forEach(function (tenant) {
            if (tenant.key === tenantKey) {
              tenant.state = state;
            }
          });
        }
        dfd.resolve(res.data);
      })
      .catch(function (err) {
        if (err.id) {
          dfd.resolve(err);
        } else {
          dfd.reject(err);
        }
      });

    return dfd.promise;
  }

  return {
    getUserTenants: getUserTenants,
    getUserTenant: getUserTenant,
    setUserTenantState: setUserTenantState,
    clear: clear
  };

}
