function BankIDFactory (
  $q,
  $http,
  $log,
  $window,
  $timeout,
  $location,
  $document,
  DICT,
  DeviceFactory,
  BankID
) {

  // Private Constants
  
  var OPEN_BANKIDAPP = 'OPEN_BANKIDAPP';
  var GOOGLE_INTENT_URL =  [
    'intent://',
    'bankid/',
    '#Intent;',
    'scheme=bankid;',
    'package=com.bankid.bus;',
    'end'
  ].join('');


  function _debug(msg) {
    $log.debug('[heimdall.bankid]\t'  + msg);
  }
  
  /**
   * @description
   * Try to make the user open its BankID app on this or another device.

   * @param {Object} opt
   * @param {Object} opt.dfd         Promise-wrapper to allow giving this flow a synchronous feel
   * @param {Object} [opt.userData]       User data if the order is to be resumed
   * @param {String} [opt.orderKey]       BankID order key if this order is to be resumed
   * @param {String} [opt.autoStartToken] Autostarttoken can be used but is not needed atm
   * @param {String} [opt.userTriggered]  Did a user gesture request the opening of the BankID app
   */
  function openBankIDApp (opt) {

    var device = DeviceFactory.getDevice();

    // we don't want to try to open bankidapp if we are on desktop
    if (!device.isMobile) {
      return null;
    }
    // We try to follow guidelines at:
    // http://www.bankid.com/Global/wwwbankidcom/RP/BankID%20Relying%20Party%20Guidelines%20v2.7.pdf

    // Kivra Mobile App
    if (device.isKivraApp) {
      $window.location.replace('bankid:///?redirect=' + $window.encodeURIComponent('kivra://'));
    }
    // Chrome iOS
    else if (device.isCriOS) {
      $window.location.replace('bankid:///?redirect=' + $window.encodeURIComponent('googlechrome://'));
    }
    // iOS
    else if (device.isIOS) {
      // add the orderkey and user data to the url so we can resume the order when we get back
      $location.search('user_data', JSON.stringify(opt.userData));
      $location.search('order_key', opt.orderKey);
      // dont let the remaining tab keep on polling!
      //          _cancelPolling();

      // a redirect url matching the one that opened the app
      var redirectUri = $window.encodeURIComponent($location.absUrl());
      // If the custom BankID URL can't be opened we can assume the app is not installed
      $timeout(function() {
        opt.dfd.reject(DICT['bankid__error--not_installed']);
      }, 200);

      $window.location.replace('bankid:///?autostarttoken='+opt.autoStartToken+'&redirect='+redirectUri);
    }

    // ANDROID etc ...
    else {
      if (opt.isChrome) {
        $window.open(GOOGLE_INTENT_URL);
      } else {
        // no redirect url given to the BankID app should work on most other devices
        $window.location.replace('bankid://');
      }
    }
    
  }

  /**
   * @description
   * Cancel polling of an ongoing bankid poll

   * @param {Object} promise              Promise of an ongoing poll
   */

  function cancel (promise) {
    (typeof promise._cancel === 'function') && promise._cancel();
  }

  function isPending (promise) {
    return (promise.$$state.status === 0); // use internal angularjs var
  }
  /**
   * @description
   * sign something with bankid

   * @param {Object} opt                Options passed needed to perform an BankID sign
   * @param {String} opt.orderKey       BankID order key to identify the current order
   * @param {String} opt.autoStartToken BankID identifier
   * @returns {Object} promise       A Promise to fulfill this signing
   */
  function sign (opt) {
    
    var dfd = $q.defer();
    var promise = dfd.promise;
    promise._cancel = cancelPromise;
    promise._complete = completePromise;
    
    function cancelPromise () {
      dfd.reject(DICT['bankid__sign--canceled']);
      $timeout.cancel(promise._pollTimeout);
    }

    // Method to allow testing signing without poll flow
    function completePromise () {
      dfd.resolve(opt.orderKey);
      $timeout.cancel(promise._pollTimeout);
    }
    
    function doPoll(res) {
      dfd.notify(DICT['bankid_' + res.message_code.toLowerCase()]);
      promise._pollTimeout = $timeout(poll, 2000);
    }
    
    function poll() {
      $http.get([
        BankID.apiUrl,
        '/v2/bankid/',
        opt.orderKey
      ].join(''))
        .success(function (res) {
          switch (res.status) {
          case 'pending':
            doPoll(res);
            break;
          case 'complete':
            dfd.resolve(opt.orderKey);
            break;
          case 'error':
            dfd.reject(DICT['bankid_' + res.message_code.toLowerCase()]);
            break;
          default:
            dfd.reject(DICT['bankid__error_generic']);
          }
        }, function (err) {
          dfd.reject(DICT['bankid_' + err.message_code.toLowerCase()]);
        });
    }
    
    $timeout(function () {
      _debug('Starting BankID poll');
      doPoll({message_code: '1'});
      dfd.notify(OPEN_BANKIDAPP);
    }, 0);

    return dfd.promise;
  }

  // externally visible:
  // ----------

  var service = {
    sign: sign,
    cancel: cancel,
    isPending: isPending,
    openBankIDApp: openBankIDApp,
    OPEN_BANKIDAPP: OPEN_BANKIDAPP
  };

  return service;
}
