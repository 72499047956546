/**
 * This provider enabales a module to toggle all setting specific routes
 * on/off during its config phase by calling userSettingsProvider.enable(bool)
 */
function BankIDProvider() {

  var provider = {
    setAPIUrl: function (url) {
      provider.apiUrl = url;
    },
    $get: function () {
      return {
        apiUrl: provider.apiUrl
      };
    }
  };
  return provider;
}
