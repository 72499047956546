/* 
 * Factory to wrap http interactions to the bank part of core's api.
 * It's methods returns promises resolving to result objects
 */

function ApiUserFactory(
  $http,
  $q,
  $sessionStorage,
  CoreApiReqFactory
) {
  
  var sessionCache = $sessionStorage.user;

  function clear () {
    sessionCache = undefined;
  }
  
  function getUser () {
    var dfd = $q.defer();
    var url = CoreApiReqFactory.getBaseUrl();

    if (sessionCache) {
      dfd.resolve(sessionCache);
    } else {
      $http
        .get(url)
        .then(
          function (res) {
            sessionCache = res.data;
            dfd.resolve(res.data);
          },
          function (err) {
            if (err.id) {
              dfd.resolve(err);
            } else {
              dfd.reject(err);
            }
          }
        );
    }
    return dfd.promise;
  }

  function updateUser (user, opt) {
    if (opt.localOnly && sessionCache) {
      angular.extend(sessionCache, user);
    }
    // @TODO put user object through core API
  }
  
  return {
    getUser: getUser,
    updateUser: updateUser,
    clear: clear
  };

}
