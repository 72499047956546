function GoBackFactory (
  $location,
  $window,
  DICT,
  $log,
  KvRedirect
) {

  var url, label;
  
  function set(opt) {
    $log.debug('[kv.goback] GoBackFactory goBackUrl set to: ', opt);
    url = opt.url;
    label = opt.label; 
  }

  function get() {
    return {
      url: url,
      label: label || DICT['bankid__default_go_back']
    };
  }

  function isSet() {
    return !!url;
  }

  function goBack() {
    url && KvRedirect.redirect(url);
    !url && $log.debug('[kv.goback] GoBackFactory.goBack: no redirectUrl has been set!');
  }
  
  var factory = {
    set: set,
    isSet: isSet,
    goBack: goBack,
    get: get
  };
  
  return factory;
  
}
