/**
 *  Makes the coreApi module configurable
 */
function coreApiProvider() {

  // defaults
  var config = {
    coreHost: 'https://sandbox.kivra.com',
    apiVersion: 'v1'
  };
  
  var provider = {
    set: function (opt) {
      config = angular.extend(config, opt);
    },
    $get: function () {
      return config;
    }
  };
  
  return provider;
}
