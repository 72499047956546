function GoBackCtrl(
  $scope,
  $log,
  GoBackFactory
) {

  var self = this;
  
  function goBack () {
    $log.debug('trying to return home');
  }
  
  angular.extend(self, {
    goBack: GoBackFactory.goBack,
    goBackData: GoBackFactory.get(),
    isSet: GoBackFactory.isSet
  });

}
