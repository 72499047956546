/* 
 * Factory to wrap http interactions to the bank part of core's api.
 * It's methods returns promises resolving to result objects
 */


function ApiBankFactory(
  $http,
  $q,
  CoreApiReqFactory
) {

  var factory = {
    getAccounts: function getBankAccounts () {
      var dfd = $q.defer();

      $http.get(CoreApiReqFactory.getBaseUrl() + '/payment_info?include=all')
        .then(
          function (res) {
            dfd.resolve(res.data);
          },
          function (err) {
            if (err.id) {
              dfd.resolve(err);
            } else {
              dfd.reject(err);
            }
          }
        );

      return dfd.promise;
    },
    
    deleteAccount: function deleteBankAccount (account) {
      var dfd = $q.defer();

      $http.delete(CoreApiReqFactory.getBaseUrl() + '/payment_info/se.oneclick_'+account)
        .then(
          function (res) {
            dfd.resolve(res.data);
          },
          function (err) {
            if (err.code === '40903') {
              dfd.reject(err.code);
            }
            else {
              dfd.reject(err);
            }
          }
        );

      return dfd.promise;
    },

    initSign: function registerBankAccountInit (account) {
      var dfd = $q.defer();

      $http.post(CoreApiReqFactory.getBaseUrl() + '/payment_option_signature', {})
        .then(
          function (res) {
            // bankid order key passed in header
            res.data.kivra_objkey = res.headers('kivra-objkey');
            dfd.resolve(res.data);
          },
          function (err) {
            dfd.reject(err.data);
          }
        );

      return dfd.promise;
    },

     addAccount: function addBankAccount (payload) {
      var dfd = $q.defer();

      $http.post(CoreApiReqFactory.getBaseUrl() + '/payment_info', payload)
        .then(
          function (res) {
            dfd.resolve(res.data);
          },
          function (err) {
            dfd.reject(err.data);
          }
        );

      return dfd.promise;
    }
  };

  return factory;

}
